import React from 'react';
import { useTranslation } from 'react-i18next';
import ventureCityLogo from "../assets/img/vc/venture-city-miami.jpg";
import techStarsLogo from '../assets/img/vc/full-color-techstars-logo.png';

export default function VCInvestors() {
  const { t } = useTranslation();

  // Array of VC investors with their logos and names
  const investors = [
    {
      name: 'Venture City',
      logo: ventureCityLogo,
      website: 'https://theventure.city/'
    },
    {
      name: 'Tech Stars',
      logo: techStarsLogo,
      website: 'https://www.techstars.com/'
    }
  ];

  return (
    <div className="bg-white py-16 sm:py-24">
      <div className="px-4 md:max-w-3xl lg:max-w-5xl xl:max-w-7xl mx-auto">
        <div className="text-center md:text-left">
          <div className="pb-4">
            <span className="text-sm tracking-widest font-semibold text-ablue-600 font-bold uppercase">
              {t('vc-investors-subtitle', 'Venture Capital')}
            </span>
          </div>
          <h2 className="text-4xl text-nblue-900 font-bold">
            {t('vc-investors-title', 'Venture Capital Partners')}
          </h2>
          <p className="font-light mt-4 text-lg text-agray-500 max-w-3xl">
            {t('vc-investors-description', 'Products developed by our team have received investment from leading venture capital firms.')}
          </p>
        </div>
        
        <div className="mx-auto mt-12 grid grid-cols-1 items-center gap-x-8 gap-y-10 sm:grid-cols-2 sm:gap-x-10">
          {investors.map((investor) => (
            <a 
              key={investor.name}
              href={investor.website}
              target="_blank"
              rel="noopener noreferrer"
              className="flex justify-center col-span-1 transition-transform hover:scale-105"
            >
              <img
                className="max-h-24 md:max-h-24 object-contain"
                src={investor.logo}
                alt={investor.name}
              />
            </a>
          ))}
        </div>

      </div>
    </div>
  );
} 