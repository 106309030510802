import MainHero from "./components/MainHero";
import MainContent from "./components/MainContent";
import WhyChooseUs from "./components/WhyChooseUs";
import Ready from "./components/Ready";
import BlogMain from "./components/blog/BlogMain";
import Awards from "./components/Awards";
import OurWorkHero from "./components/OurWorkHero";
import VCInvestors from "./components/VCInvestors";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
export default function Home(props) {

  const { i18n } = useTranslation();
  
  useEffect(() => {
    i18n.changeLanguage(props.language);
  }, [props.language, i18n]);
  
  return (
    <div className="bg-white">
      <main>
        <MainHero></MainHero>
        <MainContent />
        <OurWorkHero></OurWorkHero>
        <WhyChooseUs />
        <VCInvestors />
        <Awards />
        <BlogMain></BlogMain>
        <Ready></Ready>
      </main>
    </div>
  );
}
