import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation } from "swiper";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Fade from "react-reveal/Fade";
import ClodTech from "./CloudTech";

SwiperCore.use([Navigation, Autoplay]);

const ServiceCard = ({ title, description, image, href }) => (
  <Fade bottom>
    <Link to={href} className="hover:scale-105 transition-transform duration-300">
      <div className="bg-agray-100 h-full rounded-3xl hover:shadow-lg transition-shadow duration-300 p-6">
        <div className="h-24 flex items-start">
          <img className="h-16 w-auto object-contain" src={image} alt={title} />
        </div>
        <div className="py-6">
          <h3 className="text-2xl text-nblue-900 font-bold">{title}</h3>
          <p className="font-light mt-4 text-base md:text-lg text-agray-500">{description}</p>
        </div>
      </div>
    </Link>
  </Fade>
);

const MethodologySection = ({ title, subtitle, description, image, reverse = false }) => (
  <div className={`mt-24 ${reverse ? 'md:flex-row-reverse' : ''} md:flex md:items-center md:justify-between`}>
    <div className="md:w-1/2">
      <Fade bottom>
        <span className="text-sm md:text-md tracking-widest font-semibold text-ablue-600 font-bold uppercase">
          {title}
        </span>
        <h2 className="text-3xl md:text-4xl xl:text-5xl text-nblue-900 font-bold mt-2">
          {subtitle}
        </h2>
        <p className="font-light mt-4 text-base md:text-lg text-agray-500">
          {description}
        </p>
      </Fade>
    </div>
    <div className="md:w-1/2 mt-8 md:mt-0">
      <Fade bottom>
        <img className="w-full rounded-lg " src={image} alt={title} />
      </Fade>
    </div>
  </div>
);

export default function MainContent({ noClick, language }) {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language, i18n]);

  const baseServices = [
    {
      title: t("nearshore-service"),
      description: t("nearshore-service-desc"),
      image: require("../assets/img/home/services-nearshore-color.png"),
      href: "/services/nearshore-development",
    },
    {
      title: t("custom-web-develop"),
      description: t("custom-web-develop-desc"),
      image: require("../assets/img/home/services-web-color.png"),
      href: "/services/custom-web-development",
    },
    {
      title: t("custom-mobile-develop"),
      description: t("custom-mobile-develop-desc"),
      image: require("../assets/img/home/services-mobile-color.png"),
      href: "/services/custom-mobile-development",
    },
  ];

  const services = baseServices.map(service => ({
    ...service,
    href: currentLanguage === "en" ? service.href : `/es${service.href}`
  }));

  if (currentLanguage !== "en") {
    services.unshift(services.pop());
  }

  return (
    <div className="bg-white py-16">
      <div className="container mx-auto px-4 max-w-7xl">
        <div className="text-center mb-16">
          <span className="text-sm md:text-md tracking-widest font-semibold text-ablue-600 font-bold uppercase">
            {t("services")}
          </span>
          <h2 className="text-3xl md:text-4xl xl:text-5xl text-nblue-900 font-bold mt-2">
            {t("what-we-do")}
          </h2>
          <p className="font-light mt-4 text-base md:text-lg text-agray-500 max-w-2xl mx-auto">
          </p>
        </div>

        <div className="hidden md:grid md:grid-cols-3 gap-8 mb-16">
          {services.map((service, index) => (
            <ServiceCard key={index} {...service} />
          ))}
        </div>

        <div className="md:hidden px-4">
          <Swiper
            slidesPerView={1}
            spaceBetween={20}
            autoplay={{ delay: 5000 }}
            className="pb-8"
          >
            {services.map((service, index) => (
              <SwiperSlide key={index}>
                <ServiceCard {...service} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

        {!noClick && (
          <div className="space-y-24">
            <MethodologySection
              title={t("methodologies")}
              subtitle={t("how-we-work")}
              description={t("how-we-work-desc")}
              image={require("../assets/img/home/how-methodologies.png")}
            />
            <MethodologySection
              title={t("prototype")}
              subtitle={t("do-it-right")}
              description={t("do-it-right-desc")}
              image={require("../assets/img/home/how-prototype.png")}
              reverse
            />
            <MethodologySection
              title={t("MVP")}
              subtitle={t("get-on-road")}
              description={t("get-on-road-desc")}
              image={require("../assets/img/home/how-mvp.png")}
            />
            <MethodologySection
              title={t("support")}
              subtitle={t("we-have-back")}
              description={t("we-have-back-desc")}
              image={require("../assets/img/home/how-support.png")}
              reverse
            />
          </div>
        )}
      </div>
      {!noClick && <ClodTech />}
    </div>
  );
}
